import JSZip from 'jszip'
import pptxToJson, { PptxToJsonOption } from '@/utils/pptxToJson'
import useUpLoadResource from './useUpLoadResource'
import logger from '@evideo/logger'
import usePageLoading from './usePageLoading'

export default () => {
	const { upload } = useUpLoadResource()
	const { changeLoadingText } = usePageLoading()

	const options: PptxToJsonOption = {
		upload, // 资源上传
		afterParseSlide: (_slideFile, _slide, index, total) => {
			changeLoadingText(
				`导入PPT中（已解析${index}页内容，共${total}页）,请勿关闭窗口...`
			)
		}
	}

	const parsePptx = (file: any) => {
		return new Promise<any>((resolve, reject) => {
			const jsZip = new JSZip()
			jsZip.loadAsync(file).then(async (zip) => {
				logger.info(`jsZip file(${file.name})`, zip.files)
				try {
					const json = await pptxToJson(zip.files, options)
					resolve(json)
				} catch (e:any) {
					reject(e.message)
				}
			})
		})
	}
	return {
		parsePptx
	}
}
