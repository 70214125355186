import useRunTime from './useRunTime'
import { OssResource, ProgressFunc } from '@evideo/oss-resource'
import { Slide, SlideTheme } from '@/types/slides'
import BMF from 'browser-md5-file'
import { computed } from 'vue'
import { useStore } from '@/store'
import useExport from './useExport'
import { getImageDataURL } from '@/utils/image'
import logger from '@evideo/logger'
import { createRandomCode } from '@/utils/common'

export interface UpLoadReply {
  url: string
  md5: string
  size: number
  fileName?: string
}
let ossResource: OssResource | null

export default () => {
  const store = useStore()
  const { runtime } = useRunTime()
  const uuid = computed(() => store.state.uuid)

  /**
   * 获取资源的url地址
   *  local环境，返回 VUE_APP_LOCAL_RESOURCE_URL_PREFIX 配置的地址
   *  cloud环境，返回 oss 地址
   * @param file
   * @param progress
   */
  const getResourceUrl = async (
    file: File,
    progress?: ProgressFunc
  ): Promise<UpLoadReply> => {
    const uploadMode = runtime.uploadMode
    if (uploadMode === 'local') {
      return await getLocalResourceUrl(file)
    }

    return await upload(file, progress)
  }

  /**
   * 获取图片资源的url
   * @param file
   * @param progress
   */
  const getImageResourceUrl = async (
    file: File,
    progress?: ProgressFunc,
    limitSize = 10
  ): Promise<UpLoadReply> => {
    let url: string
    const size = file.size
    const md5String = await md5(file)
    if (size < limitSize * 1024) {
      // 10KB以内，内嵌base64
      url = await getImageDataURL(file)
      return {
        url,
        md5: md5String,
        size
      }
    }
    // 大于等于10KB，保存oss
    return await getResourceUrl(file, progress)
  }

  const getLocalResourceUrl = async (file: File): Promise<UpLoadReply> => {
    const size = file.size
    const md5String = await md5(file)
    const url = `${
      process.env.VUE_APP_LOCAL_RESOURCE_URL_PREFIX
    }/${encodeFileName(file.name)}`
    return {
      url,
      md5: md5String,
      size
    }
  }

  const createOssResource = async (): Promise<undefined | OssResource> => {
    if (ossResource || !store.state.token) {
      return
    }
    ossResource = new OssResource({
      host: process.env.VUE_APP_RESOURCE_CENTER_HOST || '',
      token: store.state.token,
      retryMax: 3
    })
    await ossResource.init()
    // if (ossResource && !ossResource.ossConfig) {
    //   console.log(1111111111)
    //   ossResource.ossConfig = await ossResource.fetchOssConfig()
    // }
    // console.log(2222222222, ossResource?.ossConfig)
    logger.info('createOssResource, ossResource initialized successfully')
  }

  const destroyOssResource = () => {
    ossResource = null
    logger.info('destroyOssResource called')
  }

  const md5 = (file: File): Promise<string> => {
    const bmf = new BMF()
    return new Promise((resolve, reject) => {
      bmf.md5(file, (err: Error, md5: string) => {
        if (err) {
          reject(err)
        }
        resolve(md5)
      })
    })
  }
  /**
   * 通用资源上传，例如图片，音频，视频
   * - 不需要 override
   *
   * @param file
   * @param progress
   */
  const upload = async (
    file: File,
    progress?: ProgressFunc
  ): Promise<UpLoadReply> => {
    await createOssResource()

    const size = file.size

    // 计算文件md5
    const md5String = await md5(file)

    const suffix = file?.name?.split('.')?.reverse()?.[0]
      ? `.${file.name.split('.').reverse()[0]}`
      : ''
    const randomFileName = createRandomCode(18) + suffix

    // 上传资源的key
    const key = `ppt/${uuid.value}/${md5String}/${encodeFileName(
      randomFileName
    )}`
    // 上传资源
    let url = ''
    url = (await ossResource!
      .upload(key, file, { cache: true, progress })
      .catch((err) => {
        logger.error('上传失败', JSON.stringify(err))
        throw err
        // throw new Error(err)
      })) as string
    return {
      url,
      md5: md5String,
      size
    }
  }

  /**
   * 上传ppt数据
   * - 需要 override
   * @param data
   */
  const uploadPpt = async (
    data: { uuid: string; slides: Slide[]; theme: SlideTheme },
    pptUrl: string
  ): Promise<UpLoadReply> => {
    await createOssResource()

    const uploadOption = {
      override: true,
      cache: false,
      ignorePathPrefix: false
    }
    const { pptToJson } = useExport()
    // 上传ppt数据的key
    let key = `ppt/${data.uuid}/${data.uuid}.json`
    if (pptUrl) {
      const reg = /(\w+):\/\/([^/:]+)(:\d*)?/
      const res = pptUrl.match(reg)
      if (res) {
        key = pptUrl.replace(`${res[0]}/`, '')
      }
      uploadOption.ignorePathPrefix = true
      // key=pptUrl.match('')
      // key = pptUrl.replace(`${ossResource!.ossConfig.download_domain}/`, '')
    }
    const blob = new Blob([pptToJson(data)], { type: '' })
    // 计算文件md5
    const file = new File([blob], key, { type: 'application/json' })
    const md5String = await md5(file)
    const url = await ossResource!.upload(key, file, uploadOption)
    return {
      url,
      md5: md5String,
      size: file.size
    }
  }

  /**
   * 取消上传
   * @param name
   * @param uploadId
   */
  const abort = async (name: string, uploadId: string) => {
    return await ossResource!.abort(name, uploadId)
  }

  const uploadMd5 = async (
    file: File,
    progress: ProgressFunc
  ): Promise<any> => {
    await createOssResource()

    // 计算文件md5
    const md5String = await md5(file)

    // 上传资源的key
    const key = `ppt/${uuid.value}/${encodeFileName(file.name)}`

    const url = await ossResource!.upload(key, file, {
      cache: true,
      progress,
      override: true
    })
    // 上传资源
    return { key, md5: md5String, url }
  }

  const dataURLtoFile = (dataurl: string, filename: string) => {
    // 将base64转换为文件
    const arr = dataurl.split(',') || []
    const mime = (arr[0].match(/:(.*?);/) || [])[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }
  const encodeFileName = (fileName: string) => {
    return fileName
      .trim()
      .replaceAll(' ', '')
      .replaceAll('(', '（')
      .replaceAll(')', '）')
  }
  // 读取oss中文件数据列表
  const getResourceList = async (url: string) => {
    if (!ossResource) {
      return
    }
    try {
      const list = await ossResource.listV2({
        delimiter: '/',
        'continuation-token': '',
        prefix: url
      })
      return list
    } catch (err) {
      console.error('获取oss resource list error ', err)
    }
  }

  return {
    destroyOssResource,
    getResourceUrl,
    getImageResourceUrl,
    upload,
    uploadPpt,
    abort,
    uploadMd5,
    dataURLtoFile,
    createOssResource,
    getResourceList
  }
}
